import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CenteredSpinner,
  FormEdit,
  FormEditType,
  FormModal,
} from "../../components";
import { useCountry } from "../../context";
import { DoulaServices } from "../../services/DoulaServices";
import { Language, ToolType } from "../../utils/commonEnums";
import Button, { ButtonTypes } from "../../components/theme/atoms/Button";
import { Form, Row, Col, Typography, message, Modal } from "antd";
import {
  BJInputFormItem,
  BJSelectFormItem,
  BJMdFormItem,
  BJContainer,
} from "../../components/theme";
import { useForm } from "react-hook-form";
import BJTable from "../../components/theme/molecules/BJTable";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { formValidationError } from "../../utils";
import { logException } from "../../utils/exceptionLogger";
import { BJDeeplinkFormInput } from "../../components/theme/molecules/formItems/BJDeeplinkFormInput";
import BJButton from "../../components/theme/atoms/Button";
import styled from "styled-components";

interface ToolForm {
  toolKey: ToolType;
  type: string;
  appType: string;
  deepLink: string;
  toolImage: string;
  translations: Record<Language, { toolName: string }>;
}

export interface DoulaToolsData {
  deepLinkData: {
    [key in ToolType]: {
      deepLink: {
        [key in string]: string;
      };
      type: string;
      toolName: {
        [key in Language]: string;
      };
      toolImage: string | null;
      appType: string;
      description?: string;
    };
  };
  llmData: {
    doualTools: {
      tools: Array<ToolType>;
      description: string;
    };
  };
}

export const DailyDoulaToolsPage = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  const [selectedTool, setSelectedTool] = useState<ToolType | null>(null);
  const [toolsData, setToolsData] = useState<DoulaToolsData | null>(null);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const { primaryLocale, allCountries, currentCountry } = useCountry();

  const validationSchema = useMemo(() => {
    const translationsShape = allCountries.reduce((acc, country) => {
      const lang = country.locales[0].key as Language;
      return {
        ...acc,
        [lang]: yup.object().shape({
          toolName: yup
            .string()
            .required(`Tool Name (${country.locales[0].label}) is required`),
        }),
      };
    }, {});

    return yup.object().shape({
      toolKey: yup.string().required("Tool Key is required"),
      type: yup.string().required("Type is required"),
      appType: yup.string().required("App Type is required"),
      deepLink: yup.string().required("Deep Link is required"),
      toolImage: yup.string().nullable(),
      translations: yup.object().shape(translationsShape),
    });
  }, [allCountries]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<ToolForm>({
    resolver: yupResolver(validationSchema),
  });

  const { type, appType } = watch();

  const {
    control: descriptionControl,
    handleSubmit: handleDescriptionSubmit,
    reset: resetDescription,
    formState: { errors: descriptionErrors },
  } = useForm({
    defaultValues: {
      description: "",
    },
  });

  const fetchToolsData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await DoulaServices.getDoulaToolsData();
      setToolsData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchToolsData();
  }, [fetchToolsData]);

  useEffect(() => {
    if (showDescriptionModal && toolsData) {
      resetDescription({
        description: toolsData.llmData.doualTools.description || "",
      });
    }
  }, [showDescriptionModal, toolsData, resetDescription]);

  const copyToNotificationDeepLink = (link: string) => {
    const url = new URL(link);
    const pathWithQuery = url.pathname + url.search;
    setValue("deepLink", pathWithQuery);
  };

  const onSave = async () => {
    try {
      setLoading(true);
      if (toolsData) {
        await DoulaServices.updateDoulaToolsData(toolsData);
        await fetchToolsData();
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to save changes");
    } finally {
      setLoading(false);
    }
  };

  const onSubmitTool = async (data: ToolForm) => {
    try {
      if (!data) return;

      const formattedTranslations = Object.entries(data.translations).reduce(
        (acc, [lang, value]) => ({
          ...acc,
          [lang]: value.toolName,
        }),
        {} as Record<Language, string>
      );

      const newTool = {
        [data.toolKey]: {
          deepLink: {
            ...toolsData?.deepLinkData[data.toolKey]?.deepLink,
            [currentCountry.abb]: data.deepLink,
          },
          type: data.type,
          appType: data.appType,
          toolName: formattedTranslations,
          toolImage: data.toolImage || null,
        },
      };

      const updatedToolsData = {
        ...toolsData,
        deepLinkData: {
          ...toolsData?.deepLinkData,
          ...newTool,
        },
        llmData: {
          ...toolsData?.llmData,
          doualTools: {
            ...toolsData?.llmData?.doualTools,
            tools: selectedTool
              ? toolsData?.llmData?.doualTools?.tools || []
              : [
                  ...(toolsData?.llmData?.doualTools?.tools || []),
                  data.toolKey as ToolType,
                ],
          },
        },
      };

      setToolsData(updatedToolsData);
      setShowModal(false);
      setSelectedTool(null);
    } catch (error) {
      logException(error);
      message.error("Failed to update doula tools data");
    }
  };

  const onSubmitDescription = async (data: { description: string }) => {
    try {
      const updatedToolsData = {
        ...toolsData,
        llmData: {
          ...toolsData?.llmData,
          doualTools: {
            ...toolsData?.llmData?.doualTools,
            description: data.description,
          },
        },
      };

      setToolsData(updatedToolsData);
      await DoulaServices.updateDoulaToolsData(updatedToolsData);
      message.success("Description updated successfully");
      setShowDescriptionModal(false);
      resetDescription();
    } catch (error) {
      logException(error);
      message.error("Failed to update description");
    }
  };

  const handleEdit = (toolKey: ToolType) => {
    const tool = toolsData?.deepLinkData[toolKey];
    if (!tool) return;
    reset();
    setValue("toolKey", toolKey);
    setValue("type", tool.type || "app");
    setValue("appType", tool.appType || "tool");
    setValue("deepLink", tool.deepLink[currentCountry.abb] || "");
    setValue("toolImage", tool.toolImage || "");
    setValue(
      "translations",
      Object.entries(tool.toolName).reduce(
        (acc, [lang, name]) => ({
          ...acc,
          [lang]: { toolName: name },
        }),
        {} as Record<Language, { toolName: string }>
      )
    );
    setSelectedTool(toolKey);
    setShowModal(true);
  };

  const listData = useMemo(() => {
    if (!toolsData?.deepLinkData) return [];
    return toolsData.llmData.doualTools.tools.map(toolKey => ({
      id: toolKey,
      key: toolKey,
      toolName:
        toolsData.deepLinkData[toolKey]?.toolName[
          primaryLocale.key as Language
        ] || toolKey,
      type: toolsData.deepLinkData[toolKey]?.type,
      appType: toolsData.deepLinkData[toolKey]?.appType,
      deepLink:
        toolsData.deepLinkData[toolKey]?.deepLink[currentCountry.abb] || "",
      toolImage: toolsData.deepLinkData[toolKey]?.toolImage,
      toolKey,
      translations: Object.entries(
        toolsData.deepLinkData[toolKey]?.toolName || {}
      )
        .map(([lang, name]) => `${lang}: ${name}`)
        .join(", "),
    }));
  }, [toolsData, primaryLocale.key, currentCountry]);

  const columns = [
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (_: any, record: { toolKey: ToolType }) => (
        <Button onClick={() => handleEdit(record.toolKey)}>Edit</Button>
      ),
    },
    { title: "Tool Name", dataIndex: "toolName", key: "toolName", width: 150 },
    {
      title: "Translations",
      dataIndex: "translations",
      key: "translations",
      width: 300,
      render: (text: string) => (
        <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
      ),
    },
    { title: "Tool Key", dataIndex: "toolKey", key: "toolKey", width: 150 },
    { title: "Type", dataIndex: "type", key: "type", width: 100 },
    { title: "App Type", dataIndex: "appType", key: "appType", width: 120 },
    {
      title: "Deep Link",
      dataIndex: "deepLink",
      key: "deepLink",
      width: 200,
      render: (text: string) => (
        <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
      ),
    },
    {
      title: "Tool Image",
      dataIndex: "toolImage",
      key: "toolImage",
      width: 200,
      render: (text: string) =>
        text ? (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        ) : (
          "-"
        ),
    },
  ];

  const appTypeOptions = useMemo(
    () => [
      { key: "tool", value: "tool", display: "Tool" },
      { key: "screen", value: "screen", display: "Screen" },
      { key: "settings", value: "settings", display: "Settings" },
    ],
    []
  );

  const typeOptions = useMemo(
    () => [
      { key: "app", value: "app", display: "App" },
      { key: "web", value: "web", display: "Web" },
    ],
    []
  );

  if (loading)
    return (
      <StyledSpinnerContainer>
        <CenteredSpinner />
      </StyledSpinnerContainer>
    );

  return (
    <BJContainer>
      <div>
        <Typography.Title level={3}>Daily Doula Tools</Typography.Title>
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          marginBottom: 16,
          alignItems: "center",
        }}
      >
        <BJButton buttonType={ButtonTypes.Save} onClick={onSave}>
          Save
        </BJButton>
        <div
          style={{
            width: 2,
            height: 24,
            backgroundColor: "gray",
            borderRadius: 100,
          }}
        />
        <Button onClick={() => setShowModal(true)}>Add tool</Button>
        <Button onClick={() => setShowDescriptionModal(true)}>
          AI Tool description
        </Button>
        <Button type="link" onClick={() => setShowInstructionsModal(true)}>
          View Instructions
        </Button>
      </div>

      <BJTable
        dataSource={listData}
        columns={columns}
        rowKey="id"
        scroll={{ x: 1200 }}
      />

      <Modal
        title="How to Add Doula Tools"
        visible={showInstructionsModal}
        onCancel={() => setShowInstructionsModal(false)}
        footer={null}
      >
        <div style={{ padding: "8px 0" }}>
          <ol style={{ paddingLeft: "20px" }}>
            <li>
              Click on the &quot;Add tool&quot; button to create a new tool
            </li>
            <li>
              Fill in the required fields:
              <ul>
                <li>
                  Tool Key - Unique identifier for the tool - eg: dailyMood,
                  courses, etc...
                </li>
                <li>Type - Choose between App or Web</li>
                <li>App Type - Select Tool, Screen, or Settings</li>
                <li>Deep Link - The Deeplink to access the tool</li>
                <li>
                  Tool Names - Add translations for each supported language
                </li>
              </ul>
            </li>
            <li>Click Save to add the tool to the list</li>
            <li>
              Use &quot;AI Tool description&quot; to modify the tool&apos;s
              description (This should be updated by a developer, please contact
              a developer to update this)
            </li>
            <li>All changes are saved when you click the main Save button</li>
          </ol>
        </div>
      </Modal>

      <FormModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedTool(null);
          reset();
        }}
        enableSave={isDirty}
        enableDelete={true}
        modalTitle={selectedTool ? `Edit Tool - ${selectedTool}` : "Add Tool"}
        messageOnSubmit={false}
        onSubmit={handleSubmit(onSubmitTool, formValidationError)}
        size="lg"
      >
        <Row gutter={24}>
          <Col span={12}>
            <BJInputFormItem
              control={control}
              fieldName="toolKey"
              label="Tool Key"
              required
              disabled={!!selectedTool}
              error={!!errors.toolKey}
              message={errors.toolKey?.message}
            />
            <BJSelectFormItem<ToolForm>
              control={control}
              fieldName="type"
              label="Type"
              required
              optionsList={typeOptions}
              error={!!errors.type}
              message={errors.type?.message}
            />
            <BJSelectFormItem<ToolForm>
              control={control}
              fieldName="appType"
              label="App Type"
              required
              optionsList={appTypeOptions}
              error={!!errors.appType}
              message={errors.appType?.message}
            />
            <BJDeeplinkFormInput
              control={control}
              error={!!errors.deepLink}
              disabled={!appType}
              disableDeepLinkModal={type === "web"}
              label={type === "web" ? "URL" : "Deep link"}
              required
              fieldName={"deepLink"}
              title="Banner"
              copyToNotificationDeepLink={copyToNotificationDeepLink}
            />
            <BJInputFormItem
              control={control}
              fieldName="toolImage"
              label="Tool Image URL"
              error={!!errors.toolImage}
              message={errors.toolImage?.message}
            />
          </Col>
          <Col span={12}>
            <Form.Item label="Tool Names">
              {allCountries.map(country => (
                <BJInputFormItem
                  key={country.locales[0].key}
                  control={control}
                  fieldName={
                    `translations.${
                      country.locales[0].key as Language
                    }.toolName` as const
                  }
                  label={`Tool Name (${country.locales[0].label})`}
                  required
                  error={
                    !!errors.translations?.[country.locales[0].key as Language]
                      ?.toolName
                  }
                  message={
                    errors.translations?.[country.locales[0].key as Language]
                      ?.toolName?.message
                  }
                />
              ))}
            </Form.Item>
          </Col>
        </Row>
      </FormModal>

      <FormModal
        show={showDescriptionModal}
        onHide={() => {
          setShowDescriptionModal(false);
          resetDescription();
        }}
        enableSave={true}
        enableDelete={false}
        modalTitle="Edit AI Tool Description"
        onSubmit={handleDescriptionSubmit(
          onSubmitDescription,
          formValidationError
        )}
        size="lg"
        modalSubTitle="Clicking save will update the description for all tools"
        messageOnSubmit={false}
      >
        <BJMdFormItem
          control={descriptionControl}
          fieldName="description"
          label="Description"
          error={!!descriptionErrors.description}
          message={descriptionErrors.description?.message}
        />
      </FormModal>
    </BJContainer>
  );
};

const StyledSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
`;
