import { Alert, Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";

import { FormEdit, FormEditType } from "../../components";
import { useArticles, useCountry } from "../../context";
import { DoulaServices } from "../../services/DoulaServices";
import { CommonContentType, ContentListType } from "../../utils";
import { ContentSelector } from "../Popular/ContentSelector";
import { toolDeepLinks } from "./constant";
import { CustomEntertainMeModal } from "./modal/CustomEntertainMeModal";

type ToolLocale = "sv" | "en" | "no" | "da";

interface EntertainMeItem {
  id: string;
  type: "article" | "videoArticle" | "tool";
}

export const EntertaineMePage = () => {
  const [managedContent, setManagedContent] = useState<CommonContent[]>([]);
  const [entertainMeContent, setEntertainMeContent] = useState<
    Partial<CommonContentV2>[]
  >([]);
  const [loading, setLoading] = useState(false);

  const { articles } = useArticles();
  const { primaryLocale, currentCountry } = useCountry();

  const formatContent = useCallback(
    (item: EntertainMeItem): Partial<CommonContentV2> | null => {
      const isArticleType = (type: string) =>
        type === "article" || type === "videoArticle";

      const isValidTool = (id: string) =>
        item.type === "tool" && toolDeepLinks[id as keyof typeof toolDeepLinks];

      const formatToolContent = (id: string): Partial<CommonContentV2> => ({
        id,
        type: CommonContentType.TOOL,
        translations: {
          [primaryLocale.key]: {
            title:
              toolDeepLinks[id as keyof typeof toolDeepLinks].toolName[
                primaryLocale.key as ToolLocale
              ],
          },
        },
      });

      const formatArticleContent = (id: string): Partial<CommonContentV2> => ({
        ...articles.find(article => article.id === id),
        type: CommonContentType.ARTICLE,
      });

      if (isArticleType(item.type)) {
        return formatArticleContent(item.id);
      }

      if (isValidTool(item.id)) {
        return formatToolContent(item.id);
      }

      return null;
    },
    [articles, primaryLocale.key]
  );

  const fetchEntertainMeContent = useCallback(async () => {
    setLoading(true);
    const content = await DoulaServices.getEntertainMeContent(
      currentCountry.abb
    );

    const formattedContent = content.list.map(formatContent).filter(Boolean);
    setEntertainMeContent(formattedContent);
    setLoading(false);
  }, [currentCountry.abb, formatContent]);

  useEffect(() => {
    fetchEntertainMeContent();
  }, [fetchEntertainMeContent]);

  const onSave = async () => {
    try {
      setLoading(true);
      const isVideoArticle = (id: string) =>
        articles.find(article => article.id === id)?.translations[
          primaryLocale.key
        ].videoUrl;

      const getContentType = (content: CommonContent) =>
        (content.type === "article"
          ? isVideoArticle(content.id)
            ? "videoArticle"
            : "article"
          : "tool") as "tool" | "article" | "videoArticle";

      const formattedData = {
        content: {
          list: managedContent.map(content => ({
            id: content.id,
            type: getContentType(content),
          })),
        },
      };

      await DoulaServices.addEntertainMeContent(
        formattedData,
        currentCountry.abb
      );
      await fetchEntertainMeContent();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormEdit
      onSubmit={onSave}
      title="Question: Entertain me"
      editType={FormEditType.ADD}
      enableSave={managedContent.length > 0}
      loading={loading}
      hideBackButton
    >
      {currentCountry.abb === "se" && (
        <Alert
          message="For Sweden, only articles will be suggested (no tools)"
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}
      <Row gutter={24}>
        <Col span={12}>
          <ContentSelector
            displayButtons={true}
            maxContentLimit={10}
            setManagedContent={setManagedContent}
            initialContent={entertainMeContent as PopularContent[]}
            contentListType={ContentListType.EntertainMeContent}
            enableOrder={false}
            customModal={{
              component: CustomEntertainMeModal,
              title: "Tool",
            }}
          />
        </Col>
      </Row>
    </FormEdit>
  );
};
