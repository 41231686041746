import { useMemo } from "react";
import { BJSelectListModal } from "../../../components/theme";
import { useCountry } from "../../../context";
import { useFilterContent } from "../../../hooks/contentCollections/useFilterContent";
import { CommonContentType, getFormattedErrorMessage } from "../../../utils";
import { toolDeepLinks } from "../constant";

type ToolLocale = "sv" | "en" | "no" | "da";

interface DataSourceType {
  id: string;
  key: string;
  toolName: string;
  toolType: string;
  toolKey: string;
  translations: {
    [key: string]: {
      title: string;
    };
  };
}

interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  selectedContent: CommonContent[];
  onAdd: (content: CommonContent[]) => void;
  maxNoOfContentLimit: number;
}

export const CustomEntertainMeModal = ({
  show,
  onHide,
  selectedContent,
  onAdd,
  maxNoOfContentLimit,
}: CustomModalProps) => {
  const { primaryLocale } = useCountry();

  const dataSource = useMemo(() => {
    return Object.entries(toolDeepLinks).map(([key, tool]) => ({
      id: key,
      key,
      toolName: tool.toolName[primaryLocale.key as ToolLocale],
      toolType: tool.type,
      toolKey: key,
      translations: {
        [primaryLocale.key]: {
          title: tool.toolName[primaryLocale.key as ToolLocale],
        },
      },
    }));
  }, [primaryLocale]);

  const {
    error,
    updatedContent,
    setError,
    selectedContentCollections,
    setSelectedContentCollections,
  } = useFilterContent<DataSourceType>(
    selectedContent,
    maxNoOfContentLimit,
    CommonContentType.TOOL,
    dataSource
  );

  const columns = [
    { title: "Tool Name", dataIndex: "toolName", key: "toolName" },
    { title: "Type", dataIndex: "toolType", key: "toolType" },
    { title: "Key", dataIndex: "toolKey", key: "toolKey" },
  ];

  const onSubmit = () => {
    try {
      onAdd(updatedContent);
      onHide();
    } catch (err) {
      setError(getFormattedErrorMessage(err));
    }
  };

  return (
    <BJSelectListModal
      show={show}
      error={error || ""}
      onHide={onHide}
      modalTitle="Select Tools"
      recordCountSuffix="Tools"
      columns={columns}
      dataSource={dataSource}
      selectedRecords={selectedContentCollections}
      updateSelectedRecords={setSelectedContentCollections}
      disable={updatedContent.length > maxNoOfContentLimit}
      onSubmit={onSubmit}
    />
  );
};
