enum AppType {
  tool = "tool",
  screen = "screen",
  settings = "settings",
}

type ToolType =
  | "dailyMood"
  | "preParentConversation"
  | "birthPlan"
  | "birthStory"
  | "podcastEpisodes"
  | "eyeColorCalculator"
  | "nameGenerator"
  | "lengthCalculator"
  | "adjustProfilePage"
  | "shareAccount"
  | "addChild"
  | "adjustDueDate"
  | "addPregnancy"
  | "support"
  | "notification"
  | "weeklyInformation"
  | "monthlyInformation"
  | "blogListPregnancy"
  | "blogListChild"
  | "myRewards"
  | "profileTab"
  | "pointsRewards"
  | "offers"
  | "courses";

type Language = "sv" | "en" | "no" | "da";

export const toolDeepLinks: {
  [key in ToolType]: {
    deepLink:
      | string
      | {
          production: string;
          development: string;
          staging: string;
        };
    type: string;
    toolName: {
      [key in Language]: string;
    };
    toolImage: string | null;
    appType: AppType;
  };
} = {
  dailyMood: {
    deepLink: "/screen/MoodTracker",
    type: "app",
    appType: AppType.tool,
    toolName: {
      sv: "Daily Mood",
      en: "Daily Mood",
      no: "Daily Mood",
      da: "Daily Mood",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2Ff19ce3ae-2a92-4304-8b1c-6792435f8de8-Daily%20mood.png?alt=media&token=1c373292-e0c0-4c00-9a38-473fcdccc787",
  },
  // Check Bab-19233 for more information on this comment
  // whiteNoise: {
  //   deepLink: {
  //     production: "/contentCollection/DUMZBIpFGEjV0qqJrMC4",
  //     development: "/contentCollection/DUMZBIpFGEjV0qqJrMC4",
  //     staging: "/contentCollection/DUMZBIpFGEjV0qqJrMC4",
  //   },
  //   type: "app",
  //   appType: AppType.tool,
  //   toolName: {
  //     sv: "White Noise",
  //     en: "White Noise",
  //     no: "White Noise",
  //     da: "White Noise",
  //   },
  //   toolImage:
  //     "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2F8d3b590b-fd6c-424b-b762-eabc5d418a8c-Frame%20502.png?alt=media&token=98dfbfa3-c52d-418a-bb00-42be16d8c853",
  // },
  preParentConversation: {
    deepLink: "https://babyjourney.se/pre-parenting/?app-referrer",
    type: "web",
    appType: AppType.tool,
    toolName: {
      sv: "Pre-parenting Conversations",
      en: "Pre-parenting Conversations",
      no: "Pre-parenting Conversations",
      da: "Pre-parenting Conversations",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2F25b1d226-7f39-43b0-9631-d0842e9d5dfb-Whitenoise-1.png?alt=media&token=bee8129a-ac40-416d-bbc2-665c1799d51f",
  },
  birthPlan: {
    deepLink: "/screen/birthplan",
    type: "app",
    appType: AppType.tool,
    toolName: {
      sv: "Förlossningsbrev",
      en: "Birth Plan",
      no: "Fødselsplan",
      da: "Fødebrev",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2F69909dbe-b27a-4f0d-ae17-b01442f56a4d-Birth%20letter.png?alt=media&token=e380326c-9739-4bd2-a277-d2043ca5321e",
  },
  birthStory: {
    deepLink: "/screen/BirthStoryLanding",
    type: "app",
    appType: AppType.tool,
    toolName: {
      sv: "Förlossningsberättelse",
      en: "Birth Story",
      no: "Fødselsfortelling",
      da: "Fødselshistorie",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2Fcc566209-7d53-4ca3-b3e8-fa87d9eef803-Birth%20story.png?alt=media&token=bc912ffc-53a7-4749-b0b8-64f50cd2aaf9",
  },
  podcastEpisodes: {
    deepLink: "/screen/PodcastList?skipNest=true",
    type: "app",
    appType: AppType.tool,
    toolName: {
      sv: "Podcastavsnitt",
      en: "Podcast Episodes",
      no: "Podcastepisoder",
      da: "Podcastepisoder",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2Fa05bbc99-054c-475a-a954-fe11e6886607-Podcast.png?alt=media&token=a0ddfcac-05d9-4ecb-aadc-ba2d20a7641f",
  },
  eyeColorCalculator: {
    deepLink: "https://babyjourney.se/ogonfargskalkylator-app",
    type: "web",
    appType: AppType.tool,
    toolName: {
      sv: "Ögonfärgskalkylator",
      en: "Eye Color Calculator",
      no: "Øyenfargekalkulator",
      da: "Øjenfarve beregner",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2Ff531d9ff-0470-4d79-8d51-b3c6e69bf6b2-O%CC%88gonfa%CC%88rg%20opt%20(kopia).png?alt=media&token=1e441fd5-a9c6-4ad9-85eb-250ac957dea5",
  },
  nameGenerator: {
    deepLink: "https://babyjourney.se/namngenerator-app",
    type: "web",
    appType: AppType.tool,
    toolName: {
      sv: "Namngenerator",
      en: "Name Generator",
      no: "Navnegenerator",
      da: "Navnegenerator",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2F8a8eb9f0-1722-47ce-937b-0e90c299bb34-namngeneratorn.png?alt=media&token=d80ccc22-ef86-45cc-8075-0401cb5eca85",
  },
  lengthCalculator: {
    deepLink: "https://babyjourney.se/langdkalkylator-app/",
    type: "web",
    appType: AppType.tool,
    toolName: {
      sv: "Längdkalkylator",
      en: "Length Calculator",
      no: "Lengdekalkulator",
      da: "Længde beregner",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2Fc00b46fd-c429-43bc-915d-56bf4b894888-La%CC%88ngdkalkylatorn.png?alt=media&token=ac23f699-ae8d-466c-9776-46456c1074a9",
  },
  adjustProfilePage: {
    deepLink: "/settings/EditProfile",
    type: "app",
    appType: AppType.settings,
    toolName: {
      sv: "Justera profilsida",
      en: "Adjust Profile Page",
      no: "Juster Profilsiden",
      da: "Juster Profilsiden",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/images%2F0b352456-dde4-4599-bb19-e88140d3831f-Gear.png?alt=media&token=c020a0d0-41ad-4651-a6c5-1432027cd01d",
  },
  shareAccount: {
    deepLink: "/settings/Share",
    type: "app",
    appType: AppType.settings,
    toolName: {
      sv: "Dela konto",
      en: "Share Account",
      no: "Del Konto",
      da: "Del Konto",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/images%2F0b352456-dde4-4599-bb19-e88140d3831f-Gear.png?alt=media&token=c020a0d0-41ad-4651-a6c5-1432027cd01d",
  },
  addChild: {
    deepLink: "/settings/SettingsScreen",
    type: "app",
    appType: AppType.settings,
    toolName: {
      sv: "Lägg till barn",
      en: "Add Child",
      no: "Legg til Barn",
      da: "Tilføj Barn",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/images%2F0b352456-dde4-4599-bb19-e88140d3831f-Gear.png?alt=media&token=c020a0d0-41ad-4651-a6c5-1432027cd01d",
  },
  adjustDueDate: {
    deepLink: "/settings/Pregnancy",
    type: "app",
    appType: AppType.settings,
    toolName: {
      sv: "Justera förlossningsdatum",
      en: "Adjust Due Date",
      no: "Juster Forfallsdato",
      da: "Juster Forfaldsdato",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/images%2F0b352456-dde4-4599-bb19-e88140d3831f-Gear.png?alt=media&token=c020a0d0-41ad-4651-a6c5-1432027cd01d",
  },
  addPregnancy: {
    deepLink: "/settings/SettingsScreen",
    type: "app",
    appType: AppType.settings,
    toolName: {
      sv: "Lägg till graviditet",
      en: "Add Pregnancy",
      no: "Legg til Graviditet",
      da: "Tilføj Graviditet",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/images%2F0b352456-dde4-4599-bb19-e88140d3831f-Gear.png?alt=media&token=c020a0d0-41ad-4651-a6c5-1432027cd01d",
  },
  support: {
    deepLink: "/settings/Support",
    type: "app",
    appType: AppType.settings,
    toolName: {
      sv: "Support",
      en: "Support",
      no: "Støtte",
      da: "Support",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/images%2F0b352456-dde4-4599-bb19-e88140d3831f-Gear.png?alt=media&token=c020a0d0-41ad-4651-a6c5-1432027cd01d",
  },
  notification: {
    deepLink: "/settings/Notifications",
    type: "app",
    appType: AppType.settings,
    toolName: {
      sv: "Aviseringar",
      en: "Notification",
      no: "Varsel",
      da: "Notifikationer",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/images%2F0b352456-dde4-4599-bb19-e88140d3831f-Gear.png?alt=media&token=c020a0d0-41ad-4651-a6c5-1432027cd01d",
  },
  weeklyInformation: {
    deepLink: "/screen/weekInformationTabbedFromHome",
    type: "app",
    appType: AppType.screen,
    toolName: {
      sv: "Veckoinformation",
      en: "Weekly Information",
      no: "Ukentlig Informasjon",
      da: "Ugentlig Information",
    },
    toolImage: null,
  },
  monthlyInformation: {
    deepLink: "/screen/monthlyInformationTabbedFromHome",
    type: "app",
    appType: AppType.screen,
    toolName: {
      sv: "Månadsinformation",
      en: "Monthly Information",
      no: "Månedlig Informasjon",
      da: "Månedlig Information",
    },
    toolImage: null,
  },
  blogListPregnancy: {
    deepLink: "/screen/BlogList?skipNest=true",
    type: "app",
    appType: AppType.screen,
    toolName: {
      sv: "Blogglista Graviditet",
      en: "Blog List Pregnancy",
      no: "Bloggliste Graviditet",
      da: "Blogliste Graviditet",
    },
    toolImage: null,
  },
  blogListChild: {
    deepLink: "/screen/ChildBlogList?skipNest=true",
    type: "app",
    appType: AppType.screen,
    toolName: {
      sv: "Blogglista Barn",
      en: "Blog List Child",
      no: "Bloggliste Barn",
      da: "Blogliste Barn",
    },
    toolImage: null,
  },
  // TODO: Review how this should be implemented
  // overview: {
  //   deepLink: "/screen/Home",
  //   type: "app",
  //   appType: AppType.screen,
  //   toolName: {
  //     sv: "Översikt",
  //     en: "Overview",
  //     no: "Oversikt",
  //     da: "Oversigt",
  //   },
  //   toolImage: null,
  // },
  myRewards: {
    deepLink: "/screen/MyRewards",
    type: "app",
    appType: AppType.screen,
    toolName: {
      sv: "Mina belöningar",
      en: "My Rewards",
      no: "Mine Belønninger",
      da: "Mine Belønninger",
    },
    toolImage: null,
  },
  profileTab: {
    deepLink: "/screen/ProfileLanding?skipNest=true&isBackEnabled=true",
    type: "app",
    appType: AppType.screen,
    toolName: {
      sv: "Profilflik",
      en: "Profile Tab",
      no: "Profilfane",
      da: "Profilfane",
    },
    toolImage: null,
  },
  pointsRewards: {
    deepLink: "/screen/PointsAndRewards?skipNest=true",
    type: "app",
    appType: AppType.screen,
    toolName: {
      sv: "Nivåer och förmåner",
      en: "Levels and benefits",
      no: "Poengbelønninger",
      da: "Niveauer og fordele",
    },
    toolImage: null,
  },
  offers: {
    deepLink: "/screen/Offers?skipNest=true",
    type: "app",
    appType: AppType.screen,
    toolName: {
      sv: "Erbjudanden",
      en: "Offers",
      no: "Tilbud",
      da: "Tilbud",
    },
    toolImage: null,
  },
  courses: {
    deepLink: "https://babyjourney.se/kurser/?app-version",
    type: "web",
    appType: AppType.tool,
    toolName: {
      sv: "Kurser",
      en: "Courses",
      no: "Kurs",
      da: "Kurser",
    },
    toolImage:
      "https://firebasestorage.googleapis.com/v0/b/baby-journey-7cd30.appspot.com/o/contentCollections%2Fb52e79d9-9166-4ff0-ac15-cb33e236d8f1-Kurser.png?alt=media&token=0a71dc70-d00b-4a14-8d8b-0a377ff77300",
  },
};
